import React, { useState, useEffect, useRef } from "react";

import HashLoader from "react-spinners/HashLoader";

import query_backend_transcription from "../backend_query";
import "./record.css";
import { ReactComponent as Microphone } from "../../assets/microphone.svg";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { ReactComponent as ErrorIcon } from "../../assets/error.svg";

import TranscriptBlock from "../transcriptBlock/transcriptBlock";

const AudioRecorder = (props) => {
  const snatch_canvas = props.snatch_canvas;
  const [isRecording, setIsRecording] = useState("before");
  const [audioBlob, setAudioBlob] = useState(null);
  const [transcription, setTranscription] = useState(null);
  const [error, setError] = useState(null);
  const textRef = useRef(null);

  const startRecording = async () => {
    snatch_canvas("record");
    setIsRecording("waitPermission");
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    setIsRecording("countdown");

    setTimeout(async () => {
      // Now start recording
      const recorder = new MediaRecorder(stream);
      const chunks = [];

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };
      recorder.onstart = () => {
        setIsRecording("recording");
      };

      recorder.onstop = () => {
        // Combine all recorded chunks into a single Blob
        const recordedBlob = new Blob(chunks, {
          type: "audio/webm",
        });
        setAudioBlob(recordedBlob);
      };

      recorder.start();
      setIsRecording(true);

      setTimeout(() => {
        recorder.stop();
        setIsRecording("recorded");
      }, 5000);
    }, 3000);
  };

  const handleUpload = async () => {
    setIsRecording("loading");
    const formData = new FormData();
    formData.append("file", audioBlob, "rec.webm");

    await query_backend_transcription(formData).then(({ resp, error }) => {
      if (error) {
        setIsRecording("error");
        setError("Internal Server Error");
      } else {
        setTranscription(resp.data);
        setIsRecording("transcript");
      }
    });
  };

  return (
    <div className="recordContainer">
      {isRecording === "countdown" ? (
        <CountdownCircleTimer
          isPlaying
          duration={3}
          colors={["#ff6ec4", "#ff6ec4", "#7873f5", "#7873f5", "#ff6ec4"]}
          colorsTime={[3, 2, 1, 0]}
          size="250"
        >
          {({ remainingTime }) => (
            <div className="innerTimerContainer">
              <label className="innerTimerLabel">Starting In</label>
              <label className="timerNumber">{remainingTime}</label>
            </div>
          )}
        </CountdownCircleTimer>
      ) : (
        ""
      )}

      {isRecording === "before" ? (
        <div className="recordWrapper">
          <div className="RecordButtonContainer" onClick={startRecording}>
            <label> Record</label>
            <Microphone
              width={"20px"}
              height={"20px"}
              fill={"white"}
              stroke={"white"}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {isRecording === "waitPermission" ? (
        <div className="recordWrapper">
          <div className="RecordButtonContainer" onClick={startRecording}>
            <h5> Allow Access</h5>
          </div>
        </div>
      ) : (
        ""
      )}

      {isRecording === "recording" ? (
        <div>
          <CountdownCircleTimer
            isPlaying
            duration={5}
            colors={["#ff6ec4", "#ff6ec4", "#7873f5", "#7873f5", "#ff6ec4"]}
            colorsTime={[3, 2, 1, 0]}
            size="250"
          >
            {({ remainingTime }) => (
              <div className="innerTimerContainer">
                <label className="innerTimerLabel">Recording</label>
                <label className="timerNumber">{remainingTime}</label>
              </div>
            )}
          </CountdownCircleTimer>
        </div>
      ) : (
        ""
      )}
      {isRecording === "recorded" && audioBlob ? (
        <div className="record_transcribe_wrapper">
          {" "}
          <audio controls>
            <source src={URL.createObjectURL(audioBlob)} type="audio/wav" />
            Your browser does not support the audio element.
          </audio>{" "}
          <div className="recordWrapper" onClick={handleUpload}>
            <h5> Transcribe</h5>
          </div>
          <div
            className="recordWrapper"
            onClick={() => {
              startRecording();
            }}
          >
            <h5> Record Again</h5>
          </div>
        </div>
      ) : (
        ""
      )}
      {isRecording === "loading" ? (
        <HashLoader
          color={"#8B72EE"}
          cssOverride={{ margin: "5vh" }}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        ""
      )}
      {isRecording === "error" ? (
        <div className="ErrorWrapper">
          <ErrorIcon
            style={{ fill: "#CF2727", stroke: "#CF2727" }}
            width="90"
            height="90"
          />
          <h4> {error}</h4>
        </div>
      ) : (
        ""
      )}
      {isRecording === "transcript" ? (
        <div className="transcriptBlock">
          <TranscriptBlock content={transcription} />
          <div
            className="recordWrapper"
            onClick={() => {
              setIsRecording("before");
              setAudioBlob(null);
              snatch_canvas("both");
            }}
          >
            <h5> Upload Again</h5>
          </div>
        </div>
      ) : (
        ""
      )}

      <div id="audioPlayerContainer"></div>
    </div>
  );
};

export default AudioRecorder;
