import React, { useState } from "react";
import "./present.css";
import Dollar from "../assets/dollarsign.svg";
import Check from "../assets/check_ccle.svg";
import Merge from "../assets/merge-vertical-svgrepo-com 1.svg";
import Secure from "../assets/secure-svgrepo-com 1.svg";

const Present = () => {
  return (
    <div className="presentWrapper">
      <h2>
        A Cutting-edge AI Model for Advanced Speech Synthesis in Tunisian
        Dialect
      </h2>
      <div className="cardsContainer">
        <div className="cardWrapper">
          <div className="iconWrapper">
            <img src={Dollar} style={{ width: "1.3em" }}></img>{" "}
          </div>
          <label>Cost Effective</label>
        </div>
        <div className="cardWrapper">
          <div className="iconWrapper">
            <img src={Check} style={{ width: "2em" }}></img>{" "}
          </div>
          <label>Highly Accurate</label>
        </div>
        <div className="cardWrapper">
          <div className="iconWrapper">
            <img src={Merge} style={{ width: "3.2em" }}></img>{" "}
          </div>
          <label>Flexible Integration</label>
        </div>
        <div className="cardWrapper">
          <div className="iconWrapper">
            <img src={Secure} style={{ width: "3em" }}></img>{" "}
          </div>
          <label>Secure</label>
        </div>
      </div>
    </div>
  );
};

export default Present;
