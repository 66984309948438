import React, { useState, useEffect } from "react";
import "./dashboard.css";
import Table from "./table/table";
import { ReactComponent as SearchIcon } from "../assets/search.svg";
import { Plus } from "react-feather";
import FooterElement from "../footerElement/footerElement";
import SecondaryNavBar from "../navbarSecondary/navbarSeconday";
import api from "../api";
import PropagateLoader from "react-spinners/PropagateLoader";
import FileUpload from "./uploadPanne/uploadPannel";
import FailurePannel from "./FailurePannel/failurePannel";
import { ReactComponent as Danger } from "../assets/danger.svg";

const Dashboard = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isloading, setIsLoading] = useState(true);
  const [fileUplaodPannelPopUp, setFileUplaodPannelPopUp] = useState(false);
  const [failurePannelTrigger, setFailurePannel] = useState(false);

  async function get_records() {
    const API_URL = process.env.REACT_APP_BACKEND_URL;
    try {
      await api.get(API_URL + "/records").then((res) => {
        setData(res.data);
        setIsLoading(false);
      });
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    get_records();
  }, []);

  const deleteItem = (index) => {
    get_records();
  };

  const itemsPerPage = 6;
  const [searchTerm, setSearchTerm] = useState("");

  function get_filtered_data() {
    if (data == null) {
      return [];
    }

    let filtered_data = data;
    if (searchTerm.length !== 0) {
      filtered_data = data.filter((element) => {
        return element.name.toLowerCase().includes(searchTerm.toLowerCase());
      });
    }

    filtered_data = filtered_data.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );

    return filtered_data;
  }

  const [currentPage, setCurrentPage] = useState(1);
  const pages = data == null ? 0 : Math.ceil(data.length / itemsPerPage);

  const nextPage = () => {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, pages));
  };

  // Function to go to the previous page
  const prevPage = () => {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  };
  const triggerFailurePannel = () => {
    setFailurePannel(true);
  };
  const killFailurePannel = () => {
    setFailurePannel(false);
  };

  return (
    <div id="dashboardContainer">
      <SecondaryNavBar />
      <div className="TitleSeciton">
        <h3> Audio Dashboard</h3>
      </div>
      <div className="filterWrapper">
        <div style={{ display: "flex", alignItems: "center" }}>
          <label>Showing {get_filtered_data().length} Entries</label>
          <div className="inputWrapper">
            <SearchIcon style={{ fill: "white", width: "17px" }} />
            <input
              placeholder="Search..."
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            ></input>
          </div>
        </div>

        <div
          className="ImportButton"
          onClick={() => {
            setFileUplaodPannelPopUp(true);
          }}
        >
          <Plus style={{ width: "14px", padding: "0 10px 0 0" }} />
          Import Audio
        </div>
      </div>
      {isloading && (
        <div
          style={{
            height: "70vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PropagateLoader color={"#73B8FF"} size={"20"} />{" "}
        </div>
      )}

      {!isloading && data && data.length !== 0 && (
        <div
          style={{
            padding: "2vh 4vw 0 4vw",
            minHeight: "58vh",
            marginBottom: "6vh",
          }}
        >
          <Table
            data={get_filtered_data()}
            deleteItem={deleteItem}
            triggerFailurePannel={triggerFailurePannel}
          />

          <div className="paginationWrapper">
            {currentPage > 1 && (
              <label onClick={prevPage} disabled={currentPage === 1}>
                Previous
              </label>
            )}
            {currentPage > 1 && (
              <span onClick={prevPage}>{currentPage - 1}</span>
            )}
            <span id="currentPagePaginationPointer">{currentPage}</span>
            {currentPage < pages && (
              <span onClick={nextPage}>{currentPage + 1}</span>
            )}
            {currentPage < pages && (
              <label onClick={nextPage} disabled={currentPage === pages}>
                Next
              </label>
            )}
          </div>
        </div>
      )}
      {fileUplaodPannelPopUp && (
        <FileUpload
          killImportPannel={() => {
            get_records();
            setFileUplaodPannelPopUp(false);
          }}
        />
      )}
      {!isloading && data && data.length === 0 && (
        <div className="notFoundWrapper" style={{ flexDirection: "column" }}>
          <Danger style={{ width: "150px" }} />
          <span>Submit your first audio file to kick things off! </span>
        </div>
      )}
      {!isloading && data == null && (
        <div className="notFoundWrapper">
          <div className="notFoundTextWrapper">
            <h2> Failed To Load Audio Files, Please Try Again Later</h2>
            <h2>!وينهم؟ مندروش</h2>
          </div>
          <Danger style={{ width: "150px" }} />
        </div>
      )}
      {failurePannelTrigger && (
        <FailurePannel killFailurePannel={killFailurePannel} />
      )}
      <FooterElement />
    </div>
  );
};

export default Dashboard;
