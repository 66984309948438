function formatDate(item) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  try {
    const date = new Date(item.submited_date["$date"]);
    const day = date.getUTCDate();
    const monthIndex = date.getUTCMonth();
    return `${monthNames[monthIndex]} ${day}${getOrdinalSuffix(day)}`;
  } catch (error) {
    return "_";
  }
}

export { formatDate };
