import React, { useCallback, useState } from "react";
import "./uploadPannel.css";
import { ReactComponent as FileUploadIcon } from "../../assets/paperupload.svg";
import { ReactComponent as DeleteRound } from "../../assets/delete.svg";
import PropagateLoader from "react-spinners/PropagateLoader";
import api from "../../api";
import { AlertCircle } from "react-feather";

function FileUpload({ killImportPannel }) {
  const API_URL = process.env.REACT_APP_BACKEND_URL;

  const [dragActive, setDragActive] = useState(false);
  const [file, setFile] = useState(null);
  const [customName, setCustomName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  // Handle drag events
  const handleDrag = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  }, []);

  // Handle drop
  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files);
    }
  }, []);

  // Handle file selection via input
  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files);
    }
  };

  // Handle files
  const handleFiles = (files) => {
    let file = files[0];
    setFile(file);
    setCustomName(file.name);
  };

  // Trigger file input click
  const onButtonClick = () => {
    document.getElementById("file-input").click();
  };

  const upload_backend = async () => {
    setError(null);
    setIsLoading(true);

    if (!file) {
      setError("No File Is Sected");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("custom_name", customName);

    const options = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress(percentCompleted);
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      const response = await api.post(
        API_URL + "/submitjob",
        formData,
        options
      );

      let lc_user = localStorage.getItem("punicsounds_user");
      let parsed = JSON.parse(lc_user);
      parsed["tokens_left"] = response.data.tokens_left;
      localStorage.setItem("punicsounds_user", JSON.stringify(parsed));

      killImportPannel();
    } catch (error) {
      if (error.status == 403) {
        setError("Insufficient Tokens");
        setIsLoading(false);
        return;
      }

      setError("Failed To Upload the File, Please Try Again Later");
      setIsLoading(false);
    }
  };

  return (
    <div className="fileUplaodPopUpContainer">
      <div className="Header">
        {" "}
        <h2> Upload New Audio</h2>
        <DeleteRound style={{ cursor: "pointer" }} onClick={killImportPannel} />
      </div>
      {error && (
        <button className="btn-warn">
          <AlertCircle />
          {error}
        </button>
      )}

      <div
        id="drop-area"
        className={dragActive ? "active" : ""}
        onDragEnter={handleDrag}
        onDragOver={handleDrag}
        onDragLeave={handleDrag}
        onDrop={handleDrop}
      >
        {!file && (
          <form className="my-form">
            <FileUploadIcon />
            <input
              type="file"
              id="file-input"
              multiple
              accept=".mp3,.wav,.flac"
              onChange={handleChange}
              style={{ display: "none" }}
            />
            {!dragActive && (
              <p>
                Drag & Drop files here or{" "}
                <span className="file-input-label" onClick={onButtonClick}>
                  choose files
                </span>
              </p>
            )}
            {!dragActive && <p>Select Mp3, Wav, Flac Type</p>}
            {dragActive && <p>Drop File</p>}
          </form>
        )}
        {file && <h3 className="dragNDropFileName">{file.name}</h3>}
      </div>
      <div className="NameWrapper">
        <input
          placeholder={customName ? customName : "Custom Name"}
          onChange={(e) => {
            setCustomName(e.target.value);
          }}
        />
      </div>

      {isLoading ? (
        <div
          style={{
            margin: "3vh auto",
            width: "90%",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div>{uploadProgress}%</div>
          <div style={{ width: "100%", backgroundColor: "#ddd" }}>
            <div
              style={{
                height: "5px",
                backgroundColor: "#73B8FF",
                width: `${uploadProgress}%`,
                borderRadius: "20px",
              }}
            />
          </div>{" "}
        </div>
      ) : (
        <button className="uploadButton" onClick={upload_backend}>
          {" "}
          Submit
        </button>
      )}
    </div>
  );
}

export default FileUpload;
