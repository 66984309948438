import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Dashboard from "./dashboard/dashboard";
import Transcript from "./transcript/transcript";
import Login from "./Login/login";
import Register from "./Register/register";
import RequireAuth from "./RequireAuth/RequireAuth";
import DocsPage from "./apiDocumentation/ApiDocumentation";
import TrackPageViews from "./analytics";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <TrackPageViews />
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/api/docs" element={<DocsPage />} />

      <Route
        path="/dashboard"
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      />
      <Route
        path="/transcript/:id"
        element={
          <RequireAuth>
            <Transcript />
          </RequireAuth>
        }
      />
    </Routes>
  </BrowserRouter>
);
