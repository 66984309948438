import React from "react";
import "./applications.css";
import applicationImage from "../assets/soundbar_svg.svg";
import { useNavigate } from "react-router-dom";
import { ChevronRight } from "react-feather";
import { Bookmark } from "react-feather";

const Applications = () => {
  let navigate = useNavigate();

  return (
    <div className="applicationWrapper">
      <div className="rightSectionWrapper">
        <img src={applicationImage} alt="" />
      </div>
      <div className="leftSectionWrapper">
        <div className="headerContainer">
          <h2>Turn Your Voice Into Actionable Notes </h2>
          <h1> Discover The Spectrum of Our Applications</h1>
        </div>
        <div className="paragraphSection">
          <label>Video & Audio Transcription</label>
          <p>
            Transform your videos and audio files into text with unparalleled
            accuracy.enhance accessibility and extend their reach across diverse
            communities.
          </p>
        </div>
        <div className="paragraphSection">
          <label>Live Discussion Transcription</label>
          <p>
            Utilize cutting-edge language models to analyze conversations in
            real-time, enabling immediate responses and interactive engagements.
          </p>
        </div>{" "}
        <div className="paragraphSection">
          <label>Easy Integration</label>
          <p>
            Integrate our transcription services effortlessly into your existing
            workflows and platforms Using our API
          </p>
        </div>
        <button
          className="startTranscribingButton"
          onClick={() => {
            navigate("dashboard");
          }}
        >
          Start Transcribing
          <ChevronRight style={{ marginLeft: "2vw", width: "14px" }} />
        </button>
        <button
          className="startTranscribingButton"
          style={{ marginLeft: "2vw" }}
          onClick={() => {
            navigate("api/docs");
          }}
        >
          Intergrate Our API
          <Bookmark style={{ marginLeft: "2vw", width: "14px" }} />
        </button>
      </div>
    </div>
  );
};
export default Applications;
