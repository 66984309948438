import React from "react";
import { ReactComponent as Danger } from "../assets/danger.svg";
import { XCircle } from "react-feather";
function PrivacyPannel(props) {
  const killPrivacyPannel = props.killPrivacyPannel;

  return (
    <div className="termsPannel">
      <div className="exitWrapper">
        <h2>Global Privacy Statement</h2>
        <XCircle
          style={{ height: "30px", width: "30px", cursor: "pointer" }}
          onClick={() => {
            killPrivacyPannel();
          }}
        />
      </div>

      <div className="termsTextWrapper">
        <span>
          Welcome to PunicSounds. We respect your
          privacy and are committed to protecting your personal data. This
          privacy policy explains how we handle and protect your personal
          information in accordance with global privacy norms and your rights in
          relation to your personal information.
        </span>

        <label> Information Collection and Use</label>
        <span>
          We collect several types of information for various purposes to
          provide and improve our service to you, including: Personal Data:
          While using our service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you ("Personal Data"). This may include, but is not limited
          to, your name, email address, and telephone number. Usage Data: We may
          also collect information on how the service is accessed and used
          ("Usage Data"). This Usage Data may include details such as your
          computer's Internet Protocol address (e.g., IP address), browser type,
          browser version, our service pages that you visit, the time and date
          of your visit, the time spent on those pages, unique device
          identifiers, and other diagnostic data. Tracking & Cookies Data: We
          use cookies and similar tracking technologies to track activity on our
          service and we hold certain information. Cookies are files with a
          small amount of data which may include an anonymous unique identifier.
          You are free to refuse our cookies if your browser permits,
        </span>

        <label> Use of Data</label>
        <span>
          Your information, including Personal Data, may be transferred to — and
          maintained on — computers located outside of your state, province,
          country, or other governmental jurisdiction where the data protection
          laws may differ from those of your jurisdiction
        </span>

        <label> Data Transfer </label>
        <span>
          I maintain the authority to grant, deny, or revoke access to the
          website for any user, entity, or third party based on considerations I
          deem appropriate, including but not limited to security concerns,
          compliance with laws, or user conduct.
        </span>

        <label>Data Disclosure</label>

        <span>
          We may disclose your Personal Data in the good faith belief that such
          action is necessary to: Comply with a legal obligation Protect and
          defend the rights or property of PunicSounds Prevent or investigate
          possible wrongdoing in connection with the service Protect the
          personal safety of users of the service or the public Protect against
          legal liability
        </span>

        <span>
          <br></br> Dated: 08/04/2024 .<br></br> Ata Kaboudi .<br></br> Owner
          and Operator of PunicSounds
        </span>
      </div>
    </div>
  );
}

export default PrivacyPannel;
