import "./table.css";
import { ReactComponent as MoreIcon } from "../../assets/more_icon.svg";
import { Trash, Flag } from "react-feather";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { formatDate } from "../../utils";

const API_URL = process.env.REACT_APP_BACKEND_URL;

const Table = (props) => {
  const data = props.data;
  const deleteItem = props.deleteItem;
  const triggerFailurePannel = props.triggerFailurePannel;

  const [pannelDisplay, setPannelDisplay] = useState(-1);
  let navigate = useNavigate();

  const deleteQuery = async (index) => {
    let item_id = data[index]._id["$oid"];
    try {
      await api.delete(API_URL + "/delete_record/" + item_id).then((res) => {
        deleteItem(index);
      });
    } catch (error) {}
  };

  function row_click_event(item) {
    if (item.status == "FAILURE") {
      triggerFailurePannel();
      return;
    }
    if (item.status == "QUEUED") {
      return;
    }
    navigate("/transcript/" + item._id["$oid"]);
  }

  return (
    <div className="table-responsive">
      <table className="table table-striped table-hover table-custom">
        <thead>
          <tr>
            <th>Audio</th>
            <th>Date</th>
            <th>Duration</th>
            <th>Type</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td
                onClick={() => {
                  row_click_event(item);
                }}
              >
                {item.name}
              </td>
              <td
                onClick={() => {
                  row_click_event(item);
                }}
              >
                {formatDate(item)}
              </td>
              <td
                onClick={() => {
                  row_click_event(item);
                }}
              >
                {item.duration}
              </td>
              <td
                onClick={() => {
                  row_click_event(item);
                }}
              >
                {item.type}
              </td>
              <td
                onClick={() => {
                  row_click_event(item);
                }}
              >
                <label className={item.status}>{item.status}</label>
              </td>
              <td>
                <MoreIcon
                  style={{ color: "white" }}
                  onClick={() => {
                    if (pannelDisplay == -1) {
                      setPannelDisplay(index);
                    } else {
                      setPannelDisplay(-1);
                    }
                  }}
                />
                {pannelDisplay == index && (
                  <div className="DropDownMenu">
                    <div
                      className="DropDownItem"
                      onClick={() => {
                        deleteQuery(index);
                        setPannelDisplay(-1);
                      }}
                    >
                      <Trash style={{ width: "14px", color: "red" }} />
                      <span>Delete</span>
                    </div>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
