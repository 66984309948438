import { useState } from "react";
import { ReactComponent as RightArrow } from "../../assets/right_arrow_.svg";

import { ReactComponent as Again } from "../../assets/again.svg";
import HashLoader from "react-spinners/HashLoader";
import { ReactComponent as ErrorIcon } from "../../assets/error.svg";
import query_backend_transcription from "../backend_query";
import "./upload.css";
import TranscriptBlock from "../transcriptBlock/transcriptBlock";

function Upload(props) {
  const snatch_canvas = props.snatch_canvas;
  const [uploadState, setUploadState] = useState("upload");
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [transcription, setTranscription] = useState(null);

  const handleFileChange = (e) => {
    snatch_canvas("upload");
    var uplaoded_file = e.target.files[0];
    if (
      !uplaoded_file.name.toLowerCase().endsWith(".wav") &&
      !uplaoded_file.name.toLowerCase().endsWith(".mp3") &&
      !uplaoded_file.name.toLowerCase().endsWith(".flac")
    ) {
      setUploadState("error");
      setError("Only .Wav Files Are Supported");
      return;
    }
    setFile(uplaoded_file);
    setUploadState("uploaded");
  };

  const handleUpload = async () => {
    setUploadState("loading");
    const formData = new FormData();
    formData.append("file", file);

    await query_backend_transcription(formData).then(({ resp, error }) => {
      if (error) {
        if (error.response && error.response.status === 400) {
          setError(error.response.data.error);
        } else {
          setError("Internal Server Error");
        }

        setUploadState("error");
      } else {
        setTranscription(resp.data);
        setUploadState("transcript");
      }
    });
  };

  return (
    <div className="uploadSectionWrapper">
      {uploadState === "upload" ? (
        <div className="file-input">
          <input
            type="file"
            id="file"
            className="file"
            onChange={handleFileChange}
          />
          <label for="file">Select file</label>
        </div>
      ) : (
        ""
      )}
      {uploadState === "uploaded" ? (
        <div className="files_list">
          <label className="fileName">{file.name}</label>
          <button onClick={handleUpload}>
            Transcribe
            <RightArrow width="12" height="12" />
          </button>
        </div>
      ) : (
        ""
      )}
      {uploadState === "loading" ? (
        <HashLoader
          color={"#73B8FF"}
          cssOverride={{ margin: "5vh" }}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        ""
      )}
      {uploadState === "transcript" ? (
        <TranscriptBlock content={transcription} />
      ) : (
        ""
      )}

      {uploadState === "error" ? (
        <div className="ErrorWrapper">
          <ErrorIcon
            style={{ fill: "#CF2727", stroke: "#CF2727" }}
            width="90"
            height="90"
          />
          <h4> {error}</h4>
        </div>
      ) : (
        ""
      )}
      {uploadState === "transcript" || uploadState === "error" ? (
        <button
          className="uploadAgainButton"
          onClick={() => {
            setUploadState("upload");
            snatch_canvas("both");
          }}
        >
          {" "}
          Upload Again
          <Again width="12" height="12" />
        </button>
      ) : (
        ""
      )}
    </div>
  );
}

export default Upload;
