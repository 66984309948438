import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import api from "../api";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import { Eye } from "react-feather";
import "./register.css";
import SecondaryNavBarr from "../navbarSecondary/navbarSeconday";
import TermsPannel from "./terms_of_services";
import PrivacyPannel from "./global_privacy";

function Register() {
  const [showPassword, setShowPässword] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [termsPannel, setTermsPannel] = useState(false);
  const [privacyPannel, setPrivacyPannel] = useState(false);

  const [authParms, setAuthParams] = useState({
    email: null,
    name: null,
    password: null,
    confirmPassword: null,
    emails_consent: true,
    terms_consent: false,
  });

  function handleLogin(e) {
    setError(null);
    e.preventDefault();

    const hasNull = Object.values(authParms).some((value) => value === null);
    if (hasNull) {
      setError("All Fields Are Required");
      return;
    }
    if (authParms.confirmPassword !== authParms.password) {
      setError("Password and Confirm Password Do not Match");
      return;
    }
    if (authParms.email.length === 0 || authParms.password.length === 0) {
      setError("No Empty Fields Allowed");
      return;
    }

    if (authParms.terms_consent == false) {
      setError("You must agree to Terms of Service");
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("email", authParms.email);
    formData.append("name", authParms.name);
    formData.append("password", authParms.password);
    formData.append("emails_consent", authParms.emails_consent);
    formData.append("terms_consent", authParms.terms_consent);

    const API_URL = process.env.REACT_APP_BACKEND_URL;

    api
      .post(process.env.REACT_APP_BACKEND_URL + "/signup", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        navigate("/Login");
      })
      .catch((error) => {
        if (error.response && error.response.status == 400) {
          setError("User Already Exists");
          setLoading(false);
          return;
        }

        setError("An error Occured , Please Try Again Later");
        setLoading(false);
      });
  }

  return (
    <div className="registerPage">
      <SecondaryNavBarr />
      <main className="auth-cover-wrapper flex-rows align-items-center flex-rows no-wrap registerPannel">
        <div className="auth-cover-sidebar-inner">
          <div className="auth-cover-card-wrapper">
            <div className="auth-cover-card p-sm-5">
              {error ? (
                <div className="alert alert-danger" role="alert">
                  {" "}
                  {error}
                </div>
              ) : (
                ""
              )}
              <h2 className="fs-20 fw-bolder mb-4 text-center">Register</h2>
              <h4 className="fs-13 fw-bold mb-2">
                Create an account To Transcribe
              </h4>
              <p className="fs-12 fw-medium text-muted">
                Let's get you all setup, so you can verify your personal account
                and set up your profile.
              </p>
              <form className="w-100 mt-4 pt-2">
                <div className="mb-4">
                  <input
                    type="namee"
                    className="form-control"
                    placeholder="Full Name"
                    required
                    onChange={(e) => {
                      setAuthParams((prevParams) => ({
                        ...prevParams,
                        name: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    required
                    onChange={(e) => {
                      setAuthParams((prevParams) => ({
                        ...prevParams,
                        email: e.target.value,
                      }));
                    }}
                  />
                </div>

                <div className="mb-4 generate-pass">
                  <div className="input-group field">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control password"
                      id="newPassword"
                      placeholder="Password"
                      onChange={(e) => {
                        setAuthParams((prevParams) => ({
                          ...prevParams,
                          password: e.target.value,
                        }));
                      }}
                    />

                    <div
                      className="input-group-text border-start bg-gray-2 c-pointer show-pass"
                      data-bs-toggle="tooltip"
                      title="Show/Hide Password"
                      onClick={() => {
                        setShowPässword(!showPassword);
                      }}
                    >
                      <i>
                        <Eye style={{ width: "15px", cursor: "pointer" }} />
                      </i>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Password again"
                    required
                    onChange={(e) => {
                      setAuthParams((prevParams) => ({
                        ...prevParams,
                        confirmPassword: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="mt-4">
                  <div className="custom-control custom-checkbox mb-2">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="receiveMial"
                      checked={!authParms.emails_consent}
                      required
                      onClick={() => {
                        setAuthParams((prevParams) => ({
                          ...prevParams,
                          emails_consent: false,
                        }));
                      }}
                    />
                    <label
                      className="custom-control-label c-pointer text-muted"
                      for="receiveMial"
                      style={{ "font-weight": "400 !important" }}
                    >
                      I don't want to receive emails about PunicSounds and
                      related Intuit product and feature updates, marketing best
                      practices, and promotions from Us.
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="termsCondition"
                      required
                      checked={authParms.terms_consent}
                      onClick={() => {
                        setAuthParams((prevParams) => ({
                          ...prevParams,
                          terms_consent: true,
                        }));
                      }}
                    />
                    <label
                      className="custom-control-label c-pointer text-muted"
                      for="termsCondition"
                      style={{ "font-weight": "400 !important" }}
                      id="termsContainer"
                    >
                      I agree to PunicSounds{" "}
                      <a
                        onClick={() => {
                          setTermsPannel(true);
                        }}
                      >
                        Terms &amp; Conditions
                      </a>{" "}
                      and have read and acknowledge the{" "}
                      <a
                        onClick={() => {
                          setPrivacyPannel(true);
                        }}
                      >
                        Global Privacy Statement.
                      </a>
                      .
                    </label>
                  </div>
                </div>
                <div className="mt-5">
                  {loading ? (
                    <button classNameName="btn btn-lg btn-primary w-100 d-flex justify-content-center">
                      <Bars
                        height="20"
                        width="40"
                        color="white"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </button>
                  ) : (
                    <button
                    className="btn btn-lg btn-primary w-100"
                      onClick={handleLogin}
                    >
                      Create Account
                    </button>
                  )}
                </div>
              </form>
              <div className="mt-5 text-muted">
                <span>Already have an account? </span>
                <a href="/login" className="fw-bold">
                  Login
                </a>
              </div>
            </div>
          </div>
        </div>
        {termsPannel && (
          <TermsPannel
            killTermsPannel={() => {
              setTermsPannel(false);
            }}
          />
        )}
        {privacyPannel && (
          <PrivacyPannel
            killPrivacyPannel={() => {
              setPrivacyPannel(false);
            }}
          />
        )}
      </main>
    </div>
  );
}

export default Register;
