import React, { useState, useEffect } from "react";
import "./hero.css";
import HeroImage from "../assets/hero_image.svg";
import { ChevronDown } from "react-feather";

const Hero = () => {
  const handleEmailClick = () => {
    const emailAddress = "ata@memorality.com";

    const mailtoLink = `mailto:${emailAddress}`;

    const emailAnchor = document.createElement("a");

    emailAnchor.setAttribute("href", mailtoLink);
    emailAnchor.click();
  };
  return (
    <div className="hero">
      <div className="LeftSectionContainer">
        <h1>Tunisian Dialect Transcriber</h1>
        <p>
          Introducing the Cutting-Edge AI Transcribing Tunisian Dialect to Text
          with Unmatched Precision and Speed Our revolutionary tool captures the
          unique nuances of the dialect, ensuring every word and phrase is
          accurately represented in written form. We offer a seamless bridge
          between spoken language and digital documentation.
        </p>
        <div className="heroButtonContianer">
          <button
            className="getStartedButton"
            onClick={() => {
              window.scrollTo({
                top: 2400,
                behavior: "smooth", // for smooth scrolling
              });
            }}
          >
            Join Waitlist
          </button>
          <button className="contactUsButton" onClick={handleEmailClick}>
            Contact Us
          </button>
        </div>
      </div>
      <div className="RighImageContainer">
        <img src={HeroImage} alt="" />
      </div>
      <div className="scrolLDownContainer">
        <label>Scroll Down</label>
        <ChevronDown />
      </div>
    </div>
  );
};

export default Hero;
