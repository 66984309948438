import React from "react";
import { useState, useEffect } from "react";
import "./statistics.css";
const Statstics = ({ title, value, description }) => {
  return (
    <div className="statisticsWrapper">
      <div className="statsCard">
        <h2>Our Story in Numbers </h2>
        <div className="statsContainer">
          <div className="statCardWrapper">
            <label>+6%</label>
            <span>Character Error Rate</span>
          </div>
          <div className="statCardWrapper">
            <label>150h</label>
            <span>Training Hours</span>
          </div>{" "}
          <div className="statCardWrapper">
            <label>60h</label>
            <span>Hours Transcribed</span>
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default Statstics;
