import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import "bootstrap/dist/css/bootstrap.min.css";
import "./login.css";
import { Bars } from "react-loader-spinner";
import SecondaryNavBarr from "../navbarSecondary/navbarSeconday";

function Login() {
  document.documentElement.classList.add("app-skin-dark");
  const [error, setEroor] = useState(null);
  const [loading, setLoadins] = useState(false);
  const navigate = useNavigate();

  const [loginParms, setLoginParams] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPässword] = useState(false);

  function handleLogin(e) {
    setEroor(null);
    e.preventDefault();

    if (loginParms.email.length === 0 || loginParms.password.length === 0) {
      setEroor("Empty username or Password");
      return;
    }

    setLoadins(true);

    const formData = new FormData();
    formData.append("username_or_email", loginParms.email);
    formData.append("password", loginParms.password);

    api
      .post(process.env.REACT_APP_BACKEND_URL + "/signin", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const token_serializedData = JSON.stringify(
            response.data.access_token
          );
          const user_serializedData = JSON.stringify(response.data.user);

          localStorage.setItem("punicsounds_login", token_serializedData);
          localStorage.setItem("punicsounds_user", user_serializedData);

          setLoadins(false);
          navigate("/dashboard");
        }
      })
      .catch((error) => {
        if (error.response && error.response.status == 401) {
          setEroor("Invalid Credentials");
          setLoadins(false);
          return;
        }
        setEroor("An Error Occured, Please Try Again Later");
        setLoadins(false);
      });
  }

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  return (
    <div className="loginPage">
      <SecondaryNavBarr />
      <main className="auth-cover-wrapper flex-rows align-items-center flex-rows no-wrap loginWrapper">
        <div className="auth-cover-sidebar-inner align-items-center justify-content-center">
          <div className="auth-cover-card-wrapper">
            <div className="auth-cover-card p-sm-5">
              {error ? (
                <div className="alert alert-danger" role="alert">
                  {" "}
                  {error}
                </div>
              ) : (
                ""
              )}
              <h2 className="fs-20 fw-bolder mb-4 text-center">Login</h2>

              <form className="w-100 mt-4 pt-2">
                <div className="mb-4">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Name or Email"
                    required
                    onChange={(e) => {
                      setLoginParams((prevParams) => ({
                        ...prevParams,
                        email: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="mb-3 flex-direction-row d-flex">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Password"
                    required
                    onChange={(e) => {
                      setLoginParams((prevParams) => ({
                        ...prevParams,
                        password: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="rememberMe"
                      />
                      <label
                        className="custom-control-label c-pointer m-1"
                        for="rememberMe"
                      >
                        Remember Me
                      </label>
                    </div>
                  </div>
                  <div>
                    <a className="fs-11 text-primary"></a>
                  </div>
                </div>
                <div className="mt-5" onClick={handleLogin}>
                  {loading ? (
                    <button className="btn btn-lg btn-primary w-100 d-flex justify-content-center">
                      <Bars
                        height="20"
                        width="40"
                        color="white"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </button>
                  ) : (
                    <button className="btn btn-lg btn-primary w-100">
                      Login
                    </button>
                  )}
                </div>
              </form>

              <div className="mt-5 text-muted">
                <span> Don't have an account? </span>
                <a href="/register" className="fw-bold">
                  Create an Account
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Login;
