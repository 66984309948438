import api from "../api";

async function query_backend_transcription(data) {
  const API_URL = process.env.REACT_APP_BACKEND_URL;
  let resp,
    err = null;
  try {
    await api
      .post(API_URL + "/demoscribe", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => (resp = res));
  } catch (error) {
    err = error;
  }
  return { resp: resp, error: err };
}

export default query_backend_transcription;
