import axios from "axios";

// Create an Axios instance
const api = axios.create();
// Set up a request interceptor
api.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("punicsounds_login");
    if (token) {
      token = token.substring(1, token.length - 1);
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.statusText == "UNAUTHORIZED"
    ) {
      localStorage.removeItem("punicsounds_login");
      localStorage.removeItem("punicsounds_user");
      if (window.location.pathname !== "/login") {
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  }
);

export default api;
