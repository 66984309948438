import "./App.css";
import SecondaryNavBar from "./navbarSecondary/navbarSeconday";
import Transcribe from "./transcribe/transcribe";
import Present from "./present/present";
import Hero from "./Hero/hero";
import Statstics from "./statistics/statistics";
import Applications from "./applications/application";
import NewsLetter from "./newsletter/newsletter";
import LineBreaker from "./LineBreaker/lineBreaker";
import Footer from "./footerElement/footerElement";

function App() {
  // Initialize Google Analytics

  return (
    <div className="App">
      <link rel="stylesheet" href="https://use.typekit.net/your-kit-id.css" />

      <SecondaryNavBar />
      <Hero />
      <Present />
      <Statstics />
      <Applications />
      <NewsLetter />
      <LineBreaker />
      <Transcribe />
      <Footer />
    </div>
  );
}

export default App;
