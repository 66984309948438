import { ReactComponent as Copy } from "../../assets/copy.svg";
import { ReactComponent as Check } from "../../assets/check.svg";
import "./transcriptBlock.css";
import { useState, useEffect } from "react";

function TranscriptBlock(props) {
  const [transcriptions, setTranscription] = useState([]);

  useEffect(() => {
    setTranscription(props.content);
  }, []);
  const joinTranscriptions = () => {
    let resp = "";
    for (let i of transcriptions) {
      resp += i.transcript + " ";
    }
    return resp;
  };

  const [isCopied, setIsCopied] = useState(
    Array(transcriptions.length).fill(false)
  );
  const [isFullCopy, setIsFullCopy] = useState(false);

  function roundToOneDecimalPlace(floatNumber) {
    return Math.round(floatNumber * 10) / 10;
  }

  const [isToggled, toggle] = useState(false);
  const callback = () => {
    toggle(!isToggled);
  };

  return (
    <div className="transcriptSection">
      <div className="toggleContainer"></div>

      {isToggled ? (
        <div className="transcriptTitleCONtainer">
          <h4>Transcript</h4>
          <h4>Start</h4>
          <h4>Duration</h4>
          <h4>Copy</h4>
        </div>
      ) : (
        ""
      )}
      {isToggled && transcriptions != null
        ? transcriptions.map((t, i) => {
            return (
              <div className="transcriptionWrapper">
                <h4 className="transcriptText" id={"transcript:" + i}>
                  {t.transcript}
                </h4>
                <h4>{roundToOneDecimalPlace(t.start_frame)}</h4>
                <h4>{roundToOneDecimalPlace(t.duration)}</h4>

                <div className="copyButton ">
                  {!isCopied[i] ? (
                    <Copy
                      title="Copy"
                      width={"25px"}
                      height={"32px"}
                      stroke={"white"}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          document.getElementById("transcript:" + i).innerHTML
                        );
                        const aux = transcriptions.map((value, index) =>
                          index === i ? true : false
                        );
                        setIsCopied(aux);
                      }}
                    />
                  ) : (
                    <Check title="Copy" width={"25px"} height={"32px"} />
                  )}
                </div>
              </div>
            );
          })
        : ""}

      {!isToggled && transcriptions != null ? (
        <div className="fullTranscriptionWrapper">
          <div className="fullCopyButton ">
            {!isFullCopy ? (
              <Copy
                title="Copy"
                width={"25px"}
                height={"32px"}
                stroke={"white"}
                onClick={() => {
                  navigator.clipboard.writeText(joinTranscriptions());

                  setIsFullCopy(true);
                }}
              />
            ) : (
              <Check title="Copy" width={"25px"} height={"32px"} />
            )}
          </div>
          <h4>{joinTranscriptions()}</h4>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default TranscriptBlock;
