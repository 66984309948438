import React from "react";
import { ReactComponent as Danger } from "../assets/danger.svg";
import { XCircle } from "react-feather";
function TermsPannel(props) {
  const killTermsPannel = props.killTermsPannel;

  return (
    <div className="termsPannel">
      <div className="exitWrapper">
        <h2>Terms of Service</h2>
        <XCircle
          style={{ height: "30px", width: "30px", cursor: "pointer" }}
          onClick={() => {
            killTermsPannel();
          }}
        />
      </div>

      <div className="termsTextWrapper">
        <label> Ownership and Control Comprehensive Declaration</label>
        <span>
          As the sole proprietor and legal owner of PunicSounds, I, Ata Kaboudi,
          hereby declare and affirm my comprehensive rights and full privileges
          over all dimensions of the website and its associated operations. This
          declaration encompasses, but is not limited to, the following explicit
          rights:
        </span>

        <label> 1. Intellectual Property: </label>
        <span>
          I hold exclusive ownership over all intellectual property related to
          the website, including its design, textual content, graphics, user
          interface, scripts, software, and any digital assets or materials
          created for or published on the platform. This extends to trademarks,
          service marks, trade names, patents, and copyrights associated with
          PunicSounds.
        </span>

        <label> 2. Content Management: </label>
        <span>
          I reserve the right to modify, replace, or remove any content,
          features, functionalities, or services offered through the website at
          any time, at my sole discretion, without prior notice to users or
          third parties.
        </span>

        <label> 3. Access Control: </label>
        <span>
          I maintain the authority to grant, deny, or revoke access to the
          website for any user, entity, or third party based on considerations I
          deem appropriate, including but not limited to security concerns,
          compliance with laws, or user conduct.
        </span>

        <label>4. Agreements and Partnerships:</label>

        <span>
          I possess the unilateral right to enter into, modify, or terminate any
          agreements or partnerships relating to the website, including those
          concerning licensing, collaboration, content sharing, or any
          commercial activity, under terms and conditions exclusively determined
          by me.
        </span>

        <label>5. Data Governance:</label>
        <span>
          I am responsible for collecting, using, managing, and protecting user
          data in strict accordance with the website's Privacy Policy. I reserve
          the right to modify this policy as necessary to adapt to operational
          changes, technological advancements, or evolving legal and regulatory
          landscapes.
        </span>

        <label>6.Policy Implementation and Enforcement:</label>
        <span>
          I have the sole authority to implement, amend, and enforce the Terms
          of Service, Privacy Policy, and any other policies or guidelines
          governing the use of the website. This includes setting standards for
          user conduct, content submission, and community interaction to ensure
          the website’s integrity and operational efficiency.
        </span>
        <label>7.Financial Decisions: </label>

        <span>
          I control all financial aspects related to the website, including
          revenue generation, expense management, and investment decisions. This
          includes determining monetization strategies, setting pricing
          policies, and managing financial accounts associated with the
          website’s operation.
        </span>

        <label> 8.Legal Compliance and Dispute Resolution:</label>
        <span>
          I commit to operating PunicSounds in compliance with applicable laws,
          regulations, and industry standards. This includes addressing legal
          disputes, copyright claims, and other legal matters in a manner that
          protects the website's interests and legal standing. By using
          PunicSounds, users acknowledge and consent to the ownership rights and
          operational controls as outlined in this comprehensive declaration. I
          pledge to exercise these rights responsibly, with a commitment to
          ethical management, user respect, and legal compliance. For inquiries
          or further clarification regarding this declaration, please contact
          ata@memorality.com .
        </span>

        <span>
          <br></br> Dated: 08/04/2024 .<br></br> Ata Kaboudi .<br></br> Owner
          and Operator of PunicSounds
        </span>
      </div>
    </div>
  );
}

export default TermsPannel;
