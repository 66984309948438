import { useState } from "react";
import "./transcribe.css";

import AudioRecorder from "./record/record";

import Cpyright from "../Copyright/copyright";
import Upload from "./upload/upload";
function Transcribe(props) {
  const [snatch_canvas, set_snatch_canvas] = useState("both");

  return (
    <div className="transcribecontainer">
      <h1>Explore Our Beta Release </h1>
      <p>
        Submit an audio file featuring spoken Tunisian Dialect, and our model
        will transcribe it into written words for you. Our platform supports
        WAV, MP3, and FLAC formats. File size is limited to 10 seconds.{" "}
      </p>

      {snatch_canvas === "upload" || snatch_canvas === "both" ? (
        <Upload snatch_canvas={set_snatch_canvas} />
      ) : (
        ""
      )}
      {snatch_canvas !== "both" ? "" : <span className="orCaluse">OR</span>}
      {snatch_canvas === "record" || snatch_canvas === "both" ? (
        <AudioRecorder snatch_canvas={set_snatch_canvas} />
      ) : (
        ""
      )}
      <Cpyright />
    </div>
  );
}

export default Transcribe;
