import React, { useCallback, useState } from "react";
import "./failurePannel.css";
import { ReactComponent as Danger } from "../../assets/danger.svg";
import { XCircle } from "react-feather";
function FailurePannel(props) {
  const killFailurePannel = props.killFailurePannel;

  return (
    <div className="failurePrompt">
      <div className="exitWrapper">
        <XCircle
          style={{ height: "30px", width: "30px", cursor: "pointer" }}
          onClick={() => {
            killFailurePannel();
          }}
        />
      </div>
      <Danger style={{ width: "150px", height: "150px" }} />
      <span>
        We're sorry your transcription didn't go as expected. Our experimental
        model is constantly learning and doing its best to improve. This
        transcription has been flagged, and our team will investigate to
        understand what went wrong. Your feedback is invaluable as it helps us
        refine and scale our model to better serve your needs. Thank you for
        your patience and for contributing to the advancement of our technology.
        We're committed to enhancing your experience and appreciate your support
        in this journey.
      </span>
    </div>
  );
}

export default FailurePannel;
