import React, { useState, useEffect } from "react";
import "./navbarSecondary.css";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ProfileCircle } from "../assets/profile-circle.svg";
import { LogOut } from "react-feather";
import { useLocation } from "react-router-dom";

const SecondaryNavBar = () => {
  const location = useLocation().pathname;
  console.log(location);
  const navigate = useNavigate("/");
  const [displayUserPannel, setDisplayUserPannel] = useState(false);
  const user_str = localStorage.getItem("punicsounds_user");
  let user = null;
  if (user_str) {
    user = JSON.parse(user_str);
  }

  return (
    <div className={`StopBar`}>
      <span
        className="Slogo"
        onClick={() => {
          navigate("/");
        }}
      >
        PunicSounds
      </span>
      {user ? (
        <div className="profileWrapepr">
          <ProfileCircle
            style={{ width: "30px", height: "35px", cursor: "pointer" }}
            onClick={() => {
              setDisplayUserPannel(!displayUserPannel);
            }}
          />
          {displayUserPannel && (
            <div className="UserPannel">
              <td>
                <span className="bigFont">{user.name}</span>
              </td>
              <td>
                <label>Email</label>
                <span>{user.email}</span>
              </td>
              <td>
                <label>Seconds Left</label>
                <span>{user.tokens_left || 0} </span>
              </td>
              <td>
                <label>Records</label>
                <span>{user.record_count || 0}</span>
              </td>
              <td
                className="hoverable"
                onClick={() => {
                  localStorage.removeItem("punicsounds_login");
                  localStorage.removeItem("punicsounds_user");
                  navigate("/Login");
                }}
              >
                <span>Log out</span>
                <LogOut style={{ width: "14px" }} />
              </td>
            </div>
          )}
        </div>
      ) : (
        <div className="rightPannel">
          <button
            onClick={() => {
              navigate("login");
            }}
          >
            {location == "/login" || location == "/register" ? "" : "Log In"}
          </button>
          <button
            onClick={() => {
              navigate("register");
            }}
          >
            {location == "/login" || location == "/register" ? "" : "Sign Up"}{" "}
          </button>
        </div>
      )}
    </div>
  );
};

export default SecondaryNavBar;
