import React, { useState, useEffect } from "react";
import "./lineBreaker.css";
import soundwave from "../assets/soundwave.svg";

const LineBreaker = () => {
  return (
    <div className="lineBreakerContainer">
      <img src={soundwave} alt="" />
    </div>
  );
};

export default LineBreaker;
