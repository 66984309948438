import React, { useState } from "react";
import "./copyright.css";

const Cpyright = () => {
  return (
    <div className="footerContainer">
      <span>© PunicSounds</span>
    </div>
  );
};
export default Cpyright;
