// src/usePageViews.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const usePageViews = () => {
  const location = useLocation();
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      window.gtag("config", "G-YGJFCVJCD6", {
        page_path:
          location.pathname + location.search.replace(/\/[0-9a-f]{24}$/i, ""),
      });
    }
  }, [location]);
};

const TrackPageViews = () => {
  usePageViews();
  return null; // This component does not render anything
};

export default TrackPageViews;
