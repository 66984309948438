import "./newsletter.css";
import ConfettiExplosion from "react-confetti-explosion";
import { useState } from "react";
import { CheckCircle } from "react-feather";
import api from "../api";

const NewsLetter = () => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [email, setEmail] = useState(null);
  const API_URL = process.env.REACT_APP_BACKEND_URL;

  const subscribe_user = async () => {
    try {
      const response = await api.post(API_URL + "/newsletter", {
        email: email,
      });
      setIsSubscribed(true);
    } catch (error) {}
  };
  return (
    <div className="NewsLetterWrapper">
      {isSubscribed && <ConfettiExplosion />}
      <h2>Subscribe To Our Newsletter</h2>
      <p>
        Become a Member of Our Vibrant Community. Access Early Releases and
        Contribute to our work
      </p>
      <div className="newsletterEmailContainer">
        <input
          placeholder="Email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        ></input>

        {isSubscribed ? (
          <button>
            {" "}
            <CheckCircle style={{ color: "white", margin: "0 1vw 0 1vw" }} />
          </button>
        ) : (
          <button onClick={subscribe_user}>Subscribe</button>
        )}
      </div>
    </div>
  );
};

export default NewsLetter;
