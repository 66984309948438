import React, { useState, useEffect } from "react";
import "./transcript.css";
import SecondaryNavBar from "../navbarSecondary/navbarSeconday";
import { ReactComponent as Time } from "../assets/sandtime.svg";
import { ReactComponent as Folder } from "../assets/folder.svg";
import { ReactComponent as Like } from "../assets/like.svg";
import { ReactComponent as Dislike } from "../assets/dislike.svg";
import { ReactComponent as Copy } from "../assets/copy.svg";
import AudioPlayer from "./AudioPlayer/audioplayer";
import { useParams } from "react-router-dom";
import api from "../api";
import HashLoader from "react-spinners/HashLoader";
import ToggleButton from "react-toggle-button";
import { Check } from "react-feather";
import SyncLoader from "react-spinners/SyncLoader";
import { ArrowLeft } from "react-feather";
import { useNavigate } from "react-router-dom";

const Transcript = () => {
  const { id } = useParams(); // `id` matches the parameter name in the route
  const API_URL = process.env.REACT_APP_BACKEND_URL;

  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [toggleTimeStamp, setToggleTimeStamp] = useState(true);
  const [feedback, setFeedback] = useState(null);
  const [isCopied, setIsCopied] = useState(false);

  function formatDateString(dateString) {
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric" };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  }

  async function send_feedback(feedback) {
    try {
      await api
        .post(
          API_URL +
            `/update-record-feedback?record_id=${id}&feedback=${feedback}`,
          {},
          {
            record_id: id,
            feedback: feedback,
          }
        )
        .then((res) => {
          feedback == "1" ? setFeedback(true) : setFeedback(false);
        });
    } catch (error) {}
  }

  async function get_transcript_data() {
    try {
      await api.get(API_URL + "/get_transcript_page/" + id).then((res) => {
        setData(res.data);
        setIsLoading(false);
      });
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    get_transcript_data();
  }, []);

  return (
    <div id="transcriptPage">
      <SecondaryNavBar />
      <div
        className="backtoDashBaordIconContainer"
        onClick={() => {
          navigate("/dashboard");
        }}
      >
        <ArrowLeft style={{ width: "17px" }} />
        <span>Dashboard</span>
      </div>

      {!isLoading && data && (
        <div className="TranscriptPageTitle">
          <h1>{data && data.name}</h1>
          <div className="TranscriptPageTitleSubsection">
            <div className="titlesubsectionIconsText">
              <Time />
              <label>{data && data.duration}</label>
            </div>

            <div className="titlesubsectionIconsText">
              <Folder />
              <label>{data && formatDateString(data.submitted_date)}</label>
            </div>
          </div>
        </div>
      )}
      {error && (
        <button
          className="btn-warn mt-5"
          style={{
            width: "fit-content",
            alignSelf: "center",
            fontFamily: "Sora",
          }}
        >
          An Error Occured, Please Try Again Later
        </button>
      )}

      {!isLoading && data && (
        <div className="transcriptBlockWrapper">
          <div className="transcriptBlockActionPannel">
            <div className="leftActionPannel">
              <span>TimeStamp</span>
              <ToggleButton
                value={toggleTimeStamp || false}
                onToggle={(value) => {
                  setToggleTimeStamp(!toggleTimeStamp);
                }}
              />
            </div>
            <div className="RightActionPannel">
              <Like
                style={{ fill: feedback == true ? "green" : "" }}
                onClick={() => {
                  send_feedback("1");
                }}
              />
              <Dislike
                style={{ fill: feedback == false ? "red" : "" }}
                onClick={() => {
                  send_feedback("0");
                }}
              />
              {!isCopied ? (
                <Copy
                  onClick={() => {
                    navigator.clipboard.writeText(
                      data.transcripts.map((el) => el.text + " ")
                    );
                    setIsCopied(true);
                  }}
                />
              ) : (
                <Check style={{ color: "white" }} />
              )}
            </div>
          </div>
          <div className="TextBlock">
            {toggleTimeStamp &&
              data &&
              data.transcripts.map((el, index) => {
                return (
                  <div className="TextBlockSegment">
                    <span>{el.start_slice}</span>
                    <label>{el.text}</label>
                  </div>
                );
              })}
            {!toggleTimeStamp && data && (
              <div className="TextBlockSegment">
                <label>
                  {data.transcripts.map((tr) => {
                    return tr.text + " ";
                  })}
                </label>
              </div>
            )}
            {data && data.status && data.status != "FULFILLED" && (
              <div className="TextBlockSegmentInProgress">
                <SyncLoader
                  visible={true}
                  color="#ffffff9b"
                  ariaLabel="infinity-spin-loading"
                />
                <span>Transcription in Progress</span>
              </div>
            )}
          </div>
        </div>
      )}
      {isLoading && (
        <div className="loaderWrapper">
          <HashLoader
            color={"#73B8FF"}
            cssOverride={{ margin: "5vh" }}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />{" "}
        </div>
      )}

      {data && (
        <AudioPlayer src={API_URL + "/audio/" + id} Duration={data.duration} />
      )}
    </div>
  );
};

export default Transcript;
/*

*/
