import "./footer.css";
import { Mail } from "react-feather";
import { useNavigate } from "react-router-dom";

const FooterElement = () => {
  const navigate = useNavigate();
  return (
    <div className="footerWrapper">
      <div className="FooterRightSection">
        <label>PunicSounds</label>
        <span>Transcription Made Easy</span>
      </div>
      <div className="FooterLeftSection">
        <div className="footerLeftCol">
          <label>PAGES</label>
          <span
            onClick={() => {
              navigate("/api/docs");
            }}
          >
            Api Docs
          </span>
          <span
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            Dashboard
          </span>
          <span
            onClick={() => {
              navigate("/");
            }}
          >
            Landing
          </span>
        </div>
        <div className="footerLeftCol">
          <label>Resources</label>
          <span
            onClick={() => {
              navigate("/register");
            }}
          >
            Terms of Services
          </span>
          <span
            onClick={() => {
              navigate("/register");
            }}
          >
            Statement of Privacy
          </span>
          <a href="mailto:ata@memorality.com">FAQ</a>
        </div>
      </div>
    </div>
  );
};

export default FooterElement;
