import React, { useState, useEffect, useRef } from "react";
import "./audioplayer.css";
import { ReactComponent as Play } from "../../assets/play_2.svg";
import { Pause } from "react-feather";

function AudioPlayer({ src, Duration }) {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(Duration);
  const [currentTime, setCurrentTime] = useState(0);

  // Play or pause the audio when isPlaying changes
  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  // Update currentTime and duration as audio plays
  useEffect(() => {
    const audio = audioRef.current;
    const setAudioData = () => {
      setCurrentTime(audio.currentTime);
    };

    const updateAudioTime = () => setCurrentTime(audio.currentTime);

    audio.addEventListener("loadeddata", setAudioData);
    audio.addEventListener("timeupdate", updateAudioTime);

    return () => {
      audio.removeEventListener("loadeddata", setAudioData);
      audio.removeEventListener("timeupdate", updateAudioTime);
    };
  }, []);

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const onScrub = (value) => {
    audioRef.current.currentTime = value;
    setCurrentTime(value);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <div className="audioReader">
      <audio ref={audioRef} src={src} preload="metadata"></audio>
      <div>
        {isPlaying ? (
          <Pause
            style={{
              width: "19px",
              height: "19px",
              stroke: "#ffffff",
              cursor: "pointer",
              transform: "translateY(50%)",
            }}
            onClick={togglePlayPause}
          />
        ) : (
          <Play
            style={{
              width: "19px",
              height: "19px",
              stroke: "#ffffff",
              cursor: "pointer",
              transform: "translateY(50%)",
            }}
            onClick={togglePlayPause}
          />
        )}
      </div>
      <div className="soundBar">
        <input
          type="range"
          value={currentTime}
          step="1"
          min="0"
          max={duration}
          onChange={(e) => onScrub(e.target.value)}
        />
      </div>
      <div className="audioTimeInfo">
        <span>{formatTime(currentTime)}</span> /{" "}
        <span>{formatTime(duration)}</span>
      </div>
    </div>
  );
}

export default AudioPlayer;

/*

/*

 <Play
          style={{
            width: "19px",
            height: "19px",
            stroke: "#ffffff",
            cursor: "pointer",
          }}
        />
        <div className="MockAudioLine"></div>
        <label>1:02</label>
*/
