import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import SecondaryNavBar from "../navbarSecondary/navbarSeconday";
import "./apiDocumentation.css";
const DocsPage = () => {
  const [markdown, setMarkdown] = useState("");

  useEffect(() => {
    // Fetch the Markdown content from an API or local file
    fetch("/api.md")
      .then((response) => response.text())
      .then((text) => setMarkdown(text))
      .catch((error) =>
        console.error("Error fetching markdown content:", error)
      );
  }, []);

  return (
    <div className="documentationPgaeWrapper">
      <SecondaryNavBar />
      <div className="mdWrapper">
        <h2 id="title"> Api Documentation</h2>

        <ReactMarkdown
          components={{
            code({ node, inline, className, children, ...props }) {
              // Check if the language is json for specific styling
              const isJson = /language-json/.test(className || "");
              return !inline && isJson ? (
                <pre
                  style={{
                    background: "black",
                    color: "white",
                    padding: "15px",
                  }}
                >
                  <code className={className} {...props}>
                    {children}
                  </code>
                </pre>
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
          }}
          children={markdown}
        />
      </div>
    </div>
  );
};

export default DocsPage;
